
export default class LandingTemplate {

    // Construct the class
    constructor() {

        var $select = $('.lp-select select');
        var $buttons = $('.lp-value');

        $buttons.off('click').on('click', (e) => {
            let $this = $(e.currentTarget);
            $select.val($this.attr('data-lp'));
        });

    }

}
