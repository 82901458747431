
/**
 * Babel polyfill
 */
import 'babel-polyfill';

import './modules/Hero';
import './modules/SmoothScroll';
import LandingTemplate from './modules/LandingTemplate';

if ( bmv.page === 'landing-template' ) {
    new LandingTemplate();
}
