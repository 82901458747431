
import Trianglify from 'Trianglify';

// var resettingCircle = false;

const $hero = $('.hero.trianglify');
if ( $hero ) {

    setTrianglify( $hero );

    var resizeTimer;
    $(window).resize(() => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
            setTrianglify( $hero );
        }, 300);
    });

    function setTrianglify( $el ) {

        $el.find('svg').remove();

        let el_width = $el.width();
        let el_height = $el.height();
    
        let el_pattern = Trianglify({
            width: el_width,
            height: el_height,
            variance: 1,
            cell_size: 75,
            stroke_width: 1
        }).svg();
    
        $el.prepend( el_pattern );
    }

    // $hero.off('mouseenter').on('mouseenter', (e) => {
    //     document.addEventListener('mousemove', onMouseMove);
    // });

    // // Get all pattern polygons.
    // const polyArray = $(hero_pattern).children();

    // // Get polygon coords and hide them.
    // let polyPoints = polyArray.map( (i, poly) => {
    //     let rect = poly.getBoundingClientRect();
    //     let point = {
    //         x: rect.left + rect.width / 2,
    //         y: rect.top + rect.height / 2
    //     };
    //     return point;
    // });

    // // Circle hover around cursor
    // function onMouseMove(e) {

    //     let $circle = $('.trianglify__circle');
    //     let radius = $circle.width() / 2;

    //     let center = {
    //         x: e.clientX,
    //         y: e.clientY
    //     };

    //     let visible_hero_height = ($hero.height() - $hero.offset()['top']);

    //     if ( resettingCircle === false && center.y > visible_hero_height ) {
    //         resettingCircle = true;
    //         resetCircle();
    //         return false;
    //     } else {
    //         resettingCircle = false;
    //         if ( $('.trianglify__circle').length < 1 ) {
    //             let circle = document.createElement('DIV');
    //             circle.classList.add('trianglify__circle');
    //             $hero.prepend( circle );
    //         }
    //     }

    //     let circleCenter = {
    //         x: center.x - radius,
    //         y: center.y - radius
    //     };

    //     $circle.css('transform', 'translate(' + circleCenter.x + 'px, ' + circleCenter.y + 'px)');

    //     Array.from(polyPoints).forEach( (point, i) => {
    //         // Swap to invert the effect.
    //         if (detectPointInCircle(point, radius, center)) {
    //             $(polyArray[i]).attr('data-visible', 'false');
    //         } else {
    //             $(polyArray[i]).attr('data-visible', 'true');
    //         }
    //     });
    // }

    // function detectPointInCircle(point, radius, center) {
    //     // Point
    //     let xp = point.x;
    //     let yp = point.y;
    //     // Center
    //     let xc = center.x;
    //     let yc = center.y;
    //     // Diameter
    //     let d = radius * radius;
    //     let isInside = Math.pow(xp - xc, 2) + Math.pow(yp - yc, 2) <= d;
    //     return isInside;
    // }

    // function resetCircle() {
    //     $('.trianglify__circle').remove();
    // }

}
